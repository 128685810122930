import React from 'react'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import Layout from '../components/layout'
// import github_icon from "../img/github-icon.svg"

export default class IndexPage extends React.Component {

  state = {
    showMenu: false
  }
  
  render() {

    return (
      <Layout>
        <section className="hero is-fullheight-with-navbar">
        <div className="hero-head">
          <nav className="navbar">
            <div className="container">
              <div className="navbar-brand">
                <span className="navbar-burger burger" onClick={() => this.setState({ showMenu: !this.state.showMenu})}>
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
              </div>
              <div className={"navbar-menu" + (this.state.showMenu ? ' is-active' : '')}>
                <div className="navbar-end">
                  <span className="navbar-item">
                    <OutboundLink
                      title="Contact me by email"
                      href="mailto:henry@blooob.co"
                      className="button is-black is-rounded is-outlined is-centered"
                    >
                      <span>Say hello</span>
                    </OutboundLink>
                  </span>
                </div>
              </div>
            </div>
          </nav>
        </div>
          <div className="hero-body">
            <div className="container has-text-centered">
              <h1 className="title is-1 is-unselectable">
                blooob <span className="tag is-rounded is-black">co</span>
              </h1>
              <h2 className="subtitle">Web development & consultancy</h2>
              
            </div>
          </div>
          <div className="hero-foot">
            <div className="container is-fluid">
              <div className="content has-text-centered" style={{ padding: '1rem' }}>
                <p>
                  <strong>blooob oü</strong>
                  <span className="heading">
                    TLN, Estonia
                  </span>
                </p>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}
